<template>
  <div>
    <!-- <a-drawer
      title="New Message"
      :width="375"
      :visible="showNewMessageDrawer"
      @close="onCloseNewMessageDrawer"
    >
      <a-form-model
        ref="messageForm"
        :rules="rules"
        layout="vertical"
        :model="newMessageForm"
      >
        <a-form-model-item label="Title" prop="title">
          <a-input
            v-model="newMessageForm.title"
            type="title"
            placeholder="Message Title"
          />
        </a-form-model-item>
        <a-form-model-item label="Message" prop="message">
          <a-textarea
            v-model="newMessageForm.message"
            placeholder="Enter your message here"
            :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </a-form-model-item>
      </a-form-model>
      <div
        class="absolute"
        :style="{
          bottom: '15px',
          right: '15px',
        }"
      >
        <a-button
          type="primary"
          :loading="sendingNewMessage"
          @click="onSendMessage"
          >Send Message</a-button
        >
      </div>
    </a-drawer> -->
    <a-drawer
      title="View or Reply Message"
      :width="width > '640' ? 575 : 320"
      :visible="showReplyMessageDrawer"
      @close="onCloseReplyMessageDrawer"
    >
      <h4 class="text-lg font-semibold">{{ selectedMessage.title }}</h4>
      <p>
        {{ selectedMessage.message }}
      </p>
      <div v-if="selectedMessage.isForAdmin !== false">
        <p class="mt-5">Reply message</p>
        <a-textarea
          v-model="newMessageForm.message"
          placeholder="Enter your message here"
          :auto-size="{ minRows: 3, maxRows: 5 }"
        />
      </div>

      <div
        v-if="selectedMessage.isForAdmin !== false"
        class="absolute"
        :style="{
          bottom: '15px',
          right: '15px',
        }"
      >
        <a-button
          type="primary"
          :disabled="newMessageForm.message === ''"
          :loading="sendingNewMessage"
          @click="sendMessage"
          >Send Message</a-button
        >
      </div>
    </a-drawer>
    <tab-headers>
      Support
      <template #text> Response to complaint form Lawyers here </template>
    </tab-headers>
    <div class="flex items-center justify-end mb-5">
      <a-button
        v-if="false"
        type="primary"
        icon="message"
        @click="showNewMessageDrawer = true"
      >
        New Message
      </a-button>
    </div>
    <div class="bg-white rounded shadow">
      <a-tabs
        v-model="activeKey"
        :tab-position="width > 640 ? 'top' : 'left'"
        default-active-key="2"
      >
        <a-tab-pane key="1" tab="Sent Messages"> </a-tab-pane>
        <a-tab-pane key="2" tab="Received Messages"> </a-tab-pane>
      </a-tabs>
      <div class="mt-3 bg-white shadow-md rounded-md">
        <div v-if="activeKey == '1'">
          <a-table
            :data-source="sentMessages"
            :columns="headersForSentMessage"
            :loading="gettingSentMessage"
          >
            <span slot="recipient" slot-scope="record">
              <span>{{ record.recipient.firstName }}</span> <br />
              <span>{{ record.recipient.lastName }}</span>
            </span>
            <span slot="date" slot-scope="record">
              <span>{{ new Date(record.createdAt).toDateString() }}</span>
              <br />
              <span>{{ new Date(record.createdAt).toTimeString() }}</span>
            </span>
            <span slot="message" slot-scope="record">
              {{ record.message.slice(0, 150) + ' ...' }}
            </span>
            <span slot="action" slot-scope="record">
              <a-button @click="showMessageDetails(record, 'view')"
                >View
              </a-button>
            </span>
          </a-table>
        </div>
        <div v-if="activeKey == '2'">
          <a-table
            :data-source="receivedMessages"
            :columns="headersForReceivedMessage"
            :loading="gettingReceivedMessage"
          >
            <span slot="sender" slot-scope="record">
              <span>{{ record.sender.firstName }}</span> <br />
              <span>{{ record.sender.lastName }}</span>
            </span>
            <span slot="date" slot-scope="record">
              <span>{{ new Date(record.createdAt).toDateString() }}</span>
              <br />
              <span>{{ new Date(record.createdAt).toTimeString() }}</span>
            </span>
            <span slot="message" slot-scope="record">
              {{ record.message.slice(0, 150) + ' ...' }}
            </span>
            <!-- <span slot="read" slot-scope="record">
            {{ record.isRead ? 'Message Read' : 'Not Read' }}
          </span> -->
            <span slot="action" slot-scope="record">
              <a-button @click="showMessageDetails(record, 'markRead')"
                >View
              </a-button>
            </span>
          </a-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import TabHeaders from '../../components/typography/TabHeaders.vue'
import messageApi from '../../api/message'

const headersForReceivedMessage = [
  {
    title: 'Sender',
    key: 'sender',
    scopedSlots: { customRender: 'sender' },
  },
  {
    title: 'Time & Date',
    key: 'date',
    scopedSlots: { customRender: 'date' },
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Message',
    key: 'message',
    scopedSlots: { customRender: 'message' },
  },
  // {
  //   title: 'Read',
  //   key: 'read',
  //   scopedSlots: { customRender: 'read' },
  // },
  {
    title: 'Action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
]

const headersForSentMessage = [
  {
    title: 'Sent To',
    key: 'recipient',
    scopedSlots: { customRender: 'recipient' },
  },
  {
    title: 'Time & Date',
    key: 'date',
    scopedSlots: { customRender: 'date' },
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Message',
    key: 'message',
    scopedSlots: { customRender: 'message' },
  },
  {
    title: 'Action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
]

export default {
  name: 'AdminMessage',
  components: { TabHeaders },
  layout: 'admindashboard',
  data() {
    return {
      activeKey: '1',
      activeTab: false,
      headersForSentMessage,
      headersForReceivedMessage,
      showNewMessageDrawer: false,
      showReplyMessageDrawer: false,
      selectedMessage: {},
      sendingNewMessage: false,
      gettingReceivedMessage: false,
      gettingSentMessage: false,
      receivedMessages: [],
      sentMessages: [],
      newMessageForm: {
        message: '',
        title: '',
      },
      rules: {
        title: { required: true, message: 'Please fill this field' },
        message: { required: true, message: 'Please fill this field' },
      },
    }
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    width() {
      return window.innerWidth > 0 ? window.innerWidth : screen.width
    },
  },
  created() {
    this.getMessages()
    this.getSentMessages()
  },
  methods: {
    async showMessageDetails(record) {
      // if (which !== 'view') {
      //   await messageApi(this.axios).markRead({ messageId: record._id })
      // }

      this.selectedMessage = record
      this.showReplyMessageDrawer = true
    },
    onCloseNewMessageDrawer() {
      this.showNewMessageDrawer = false
    },
    onCloseReplyMessageDrawer() {
      this.showReplyMessageDrawer = false
    },
    async getSentMessages() {
      this.gettingSentMessage = true
      const req = await messageApi(this.axios).get(
        {
          type: 'all',
        },
        {
          isForAdmin: false,
          sender: this.user._id,
          populate: ['recipient'],
        }
      )
      this.sentMessages = req.data.data
      this.gettingSentMessage = false
    },
    async getMessages() {
      this.gettingReceivedMessage = true
      const req = await messageApi(this.axios).get(
        {
          type: 'all',
        },
        {
          isForAdmin: true,
          populate: ['sender'],
        }
      )
      this.receivedMessages = req.data.data
      this.gettingReceivedMessage = false
    },
    async sendMessage() {
      this.sendingNewMessage = true
      const req = await messageApi(this.axios).send({
        message: this.newMessageForm.message,
        title: `Reply:${this.selectedMessage.title}`,
        recipientId: this.selectedMessage.sender,
        sendTo: 'user',
      })
      if (req.error) {
        this.$notification.error({
          message: 'Unable to send message',
          description: req.message,
        })
        this.sendingNewMessage = false
        return
      }
      this.$notification.success({
        message: 'Message sent successfully',
        description: req.message,
      })
      this.getMessages()
      this.showReplyMessageDrawer = false
      this.newMessageForm.title = ''
      this.newMessageForm.message = ''
      this.sendingNewMessage = false
    },
  },
}
</script>
